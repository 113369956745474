export const WORDS = [
    'Acute',
    'Clips',
    'Coils',
    'Crani',
    'Focal',
    'Fossa',
    'Pupil',
    'Shunt',
    'Spina',
    'Spine',
    'Tumor',
    'Ulnar',
    'Vagus',
    'Seize',
    'Ilium',
    'Gyrus',
    'Fiber',
    'Optic',
    'Sulci',
    'Axons',
    'Tract',
    'Vagal',
    'Stria',
    'Fovea',
    'Cornu',
    'Ammon',
    'Atlas',
    'Broca',
    'Lobes',
    'Nasal',
    'Drain',
    'Screw',
    'Spasm',
    'Nerve',
    'Sense',
]
